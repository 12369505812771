import styled from 'styled-components';

import { palette } from '@/theme';

export const FullScreenWrapper = styled.div`
  position: relative;
  top: 0;
  left: -62px;
  width: calc(100% + 2 * 62px);
  border-top: 2px solid;
  border-color: ${palette.black};
  padding: 0 62px;
  @media (max-width: 991px) {
    left: -16px;
    width: calc(100% + 2 * 16px);
    border-width: 1px;
    padding: 0 16px;
  }
`;
