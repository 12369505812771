import styled from 'styled-components';

import { palette } from '@/theme';

import { Button } from '../styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 2px solid ${palette.black};
  @media (max-width: 991px) {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-width: 1px;
  }
`;

export const Wrapper = styled.div`
  width: 24px;
  height: 24px;
  transition: transform 0.5s;
  transform: scale(0);
  ${({ selected }) => selected && 'transform: scale(1)'};
  ${Button}:hover & {
    transform: scale(1);
  }
  @media (max-width: 991px) {
    &,
    & > svg {
      width: 12px;
      height: 12px;
    }
    & > svg {
      transform: translate(0, -6px);
    }
    & > svg > path {
      transform: scale(0.5);
    }
  }
`;
