import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';
import { Container, Button, Text, Step } from './styles';

const Switcher = forwardRef(({ onPrev, onNext, step, disabled }, ref) => (
  <Container ref={ref}>
    <Button type="button" onClick={onPrev} disabled={disabled}>
      <Icon />
      <Text>Zurück</Text>
    </Button>
    <Button type="button" onClick={onNext} disabled={disabled}>
      <Text>Vorwärts</Text>
      <Icon />
    </Button>
    <Step>{`Schritt ${step}/3`}</Step>
  </Container>
));

Switcher.defaultProps = { disabled: false };
Switcher.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export default Switcher;
