import styled from 'styled-components';

import { palette, fonts } from '@/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  border-top: 2px solid ${palette.black};
  @media (hover) {
    &:hover {
      box-shadow: ${({ disabled }) => (disabled ? 'none' : `inset 0 0 0 3px ${palette.black}`)};
    }
  }
  @media (max-width: 991px) {
    min-height: 30px;
    border-width: 1px;
  }
  @media (max-width: 991px) and (hover) {
    &:hover {
      box-shadow: ${({ disabled }) => (disabled ? 'none' : `inset 0 0 0 2px ${palette.black}`)};
    }
  }
`;

export const InputWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-right: 2px solid ${palette.black};
  @media (max-width: 991px) {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-width: 1px;
  }
`;

export const Input = styled.input`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 2px solid ${palette.black};
  background-color: ${palette.white};
  cursor: pointer;
  &:checked {
    background-color: ${palette.black};
  }
  &:disabled {
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    width: 20px;
    height: 20px;
    border-width: 1px;
  }
`;

export const Label = styled.label`
  flex: 1 1 auto;
  ${fonts.text}
  padding: 0 15px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  @media (max-width: 991px) {
    ${fonts.smallText22}
    padding: 0 5px;
  }
`;
