import styled from 'styled-components';

import { fonts, palette } from '@/theme';

const { black, white, red } = palette;

export const Container = styled.div`
  width: 100%;
  position: relative;
  &:before {
    content: '${({ placeholder, error }) => error || placeholder}';
    position: absolute;
    top: 0;
    right: 0;
    ${fonts.smallText22}
    color: ${({ error, isFocused }) => (error && red) || (isFocused && white) || black};
    padding: 3px 3px 1px;
    border-width: 0 0 2px 2px;
    border-style: solid;
    border-color: ${({ error }) => (error ? red : black)};
    background-color: ${({ error, isFocused }) => (error && 'transparent') || (isFocused && black) || 'transparent'};
  }
  @media (max-width: 991px) {
    &:before {
      ${fonts.smallText13}
      border-width: 0 0 1px 1px;
      padding: 2px 3px 1px;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 60px;
  background-color: ${white};
  ${fonts.text}
  color: ${({ error }) => (error ? red : black)};
  box-shadow: ${({ error }) => {
    if (error) return `inset 0 0 0 2px ${red}`;

    return 'none';
  }};
  padding: 0 60px 0 15px;
  &:focus {
    box-shadow: ${({ error }) => {
      if (error) return `inset 0 0 0 2px ${red}`;

      return `inset 0 0 0 3px ${black}`;
    }};
  }
  &::placeholder {
    color: ${black};
  }
  @media (max-width: 991px) {
    height: 30px;
    ${fonts.smallText22}
    box-shadow: ${({ error }) => {
      if (error) return `inset 0 0 0 1px ${red}`;

      return 'none';
    }};
    padding: 0 55px 0 8px;
    &:focus {
      box-shadow: ${({ error }) => {
        if (error) return `inset 0 0 0 1px ${red}`;

        return `inset 0 0 0 2px ${black}`;
      }};
    }
  }
`;
