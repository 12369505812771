import React from 'react';
import PropTypes from 'prop-types';

import { Container, Wrapper } from './styles';

const Icon = ({ selected }) => (
  <Container>
    <Wrapper selected={selected}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <path fill="none" fillRule="evenodd" stroke="#000" strokeWidth="2" d="M12 0 L12 24 M0 12 L24 12" />
      </svg>
    </Wrapper>
  </Container>
);

Icon.defaultProps = { selected: false };
Icon.propTypes = { selected: PropTypes.bool };

export default Icon;
