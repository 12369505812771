import React from 'react';

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="40" viewBox="0 0 25 40">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <path d="M2 1L24 20 2 39" />
    </g>
  </svg>
);

export default Icon;
