import styled from 'styled-components';

import { fonts, palette } from '@/theme';
import DefInput from '@/components/Input';

export const Input = styled(DefInput)`
  & > input {
    border-top: ${({ error }) => (error ? 'none' : '2px solid')};
    border-color: ${palette.black};
  }
  &:first-child > input {
    border: none;
  }
  @media (max-width: 991px) {
    & > input {
      border-width: 1px;
    }
  }
`;

export const MembershipWrap = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

export const Description = styled.p`
  padding: 0 10px;
  ${fonts.smallText22}
  &:first-child {
    padding-top: 5px;
  }
  &:last-child {
    padding-bottom: 5px;
  }
  @media (max-width: 991px) {
    padding: 0 5px;
    ${fonts.smallText13}
    &:first-child {
      padding-top: 3px;
    }
    &:last-child {
      padding-bottom: 3px;
    }
  }
`;
