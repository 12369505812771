import { object, string } from 'yup';

import { COMPANY, FIRST_NAME, LAST_NAME, ADDRESS, ZIP_CODE, CITY, EMAIL, KIND } from '@/server-api/consts';

export const validationSchema = object().shape({
  [KIND]: string().required('Ungültig'),
  [COMPANY]: string().when(KIND, {
    is: (kind) => kind === 'private',
    then: string().notRequired(),
    otherwise: string().required('Firma fehlt'),
  }),
  [FIRST_NAME]: string().required('Vorname fehlt'),
  [LAST_NAME]: string().required('Nachname fehlt'),
  [ADDRESS]: string().required('Strasse fehlt'),
  [ZIP_CODE]: string().required('PLZ fehlt'),
  [CITY]: string().required('Ort fehlt'),
  [EMAIL]: string().email('E-Mail-Adresse ungültig').required('E-Mail-Adresse fehlt'),
});
