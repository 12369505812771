/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import { values } from 'ramda';
import PropTypes from 'prop-types';

import { MEMBERSHIP_TYPES } from '@/server-api/consts';

import Icon from './Icon';
import { Button, Wrapper, TextWrap, Title, Text, ItemsWrap, Item, ItemText } from './styles';

const Membership = ({ type, name, price, defaultPrice, benefits, selected, onChange }) => {
  const handleClick = useCallback(() => onChange(type, defaultPrice), [defaultPrice, onChange, type]);

  return (
    <Button type="button" onClick={handleClick} disabled={selected}>
      <Wrapper>
        <TextWrap>
          <Title>{name}</Title>
          <Text>{price}</Text>
        </TextWrap>
      </Wrapper>
      <ItemsWrap>
        {benefits.map((benefit, index) => (
          <Item key={index}>
            <Icon selected={selected} />
            <ItemText>{benefit}</ItemText>
          </Item>
        ))}
      </ItemsWrap>
    </Button>
  );
};

Membership.defaultProps = {
  selected: false,
  onChange: () => null,
  defaultPrice: 0,
};
Membership.propTypes = {
  type: PropTypes.oneOf(values(MEMBERSHIP_TYPES)).isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  defaultPrice: PropTypes.number,
  benefits: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Membership;
