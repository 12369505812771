/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { omit } from 'ramda';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import { useAsyncState, useObserver } from '@/hooks';
import { extractYupErrors } from '@/utils';
import { KIND, MEMBERSHIP, PUBLISH, PAYMENT, AMOUNT, CURRENCY, PAYMENT_METHOD } from '@/server-api/consts';
import Membership from '../Membership';
import Checkbox from '../Checkbox';
import Switcher from '../Switcher';
import { FullScreenWrapper } from '../styles';

import { validationSchema } from './validationSchema';
import { Input, MembershipWrap, Description } from './styles';

const Step1 = ({ pageContext, location }) => {
  const { kinds, benefits, checkbox, descriptions } = pageContext;
  const inputsRef = useRef();
  const kindRef = useRef();
  const switchRef = useRef();
  const [values, setValues] = useState(omit(['key'], location.state));
  const [errors, setErrors] = useAsyncState({});

  const kind = values[KIND];
  const kindData = kind && kinds[kind];
  const { setPosition } = useObserver();

  const handleNext = useCallback(async () => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      navigate('/goenner/schritt-2/', { state: values });
    } catch (validateError) {
      setErrors(extractYupErrors(validateError));
      setPosition(inputsRef);

      try {
        if (inputsRef.current) inputsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
      }
    }
  }, [setErrors, setPosition, values]);
  const handleBack = useCallback(() => navigate('/goenner/'), []);
  const handleChange = useCallback(
    ({ currentTarget }) => {
      const { name, value } = currentTarget;

      setErrors(($) => ($[name] ? omit([name], $) : $));
      setValues(($) => ({ ...$, [name]: value }));

      if (validationSchema.isValidSync(values)) setPosition(kindRef);
    },
    [setErrors, setPosition, values]
  );
  const setMembership = useCallback(
    (type, amount) => {
      setValues(($) => ({ ...$, [MEMBERSHIP]: type, [PAYMENT]: { ...$[PAYMENT], [AMOUNT]: amount } }));
      setPosition(switchRef);
    },
    [setPosition]
  );
  const setPublish = useCallback(
    ({ target }) => {
      setValues(($) => ({ ...$, [PUBLISH]: target.checked }));
      setPosition(switchRef);
    },
    [setPosition]
  );

  useEffect(() => {
    if (!kindData) navigate('/goenner/');
  }, [kindData]);
  useEffect(() => {
    setPosition(inputsRef);
  }, [setPosition]);

  if (!kindData) return null;

  return (
    <div>
      <div ref={inputsRef}>
        {kindData.inputs.map(({ key, name }) => (
          <Input key={key} name={key} placeholder={name} value={values[key] || ''} onChange={handleChange} error={errors[key]} />
        ))}
      </div>
      <FullScreenWrapper ref={kindRef}>
        <MembershipWrap>
          {kindData.memberships.map(({ key, name, priceRange, values: prices }) => (
            <Membership
              key={key}
              type={key}
              name={name}
              price={priceRange}
              defaultPrice={prices[0]}
              benefits={benefits[key]}
              selected={values[MEMBERSHIP] === key}
              onChange={setMembership}
            />
          ))}
        </MembershipWrap>
        <Checkbox type={PUBLISH} selected={values[PUBLISH]} onChange={setPublish} label={checkbox} />
      </FullScreenWrapper>
      <FullScreenWrapper>
        {descriptions.map((description, index) => (
          <Description key={index}>{description}</Description>
        ))}
      </FullScreenWrapper>
      <Switcher ref={switchRef} onPrev={handleBack} onNext={handleNext} step={1} />
    </div>
  );
};

Step1.propTypes = {
  pageContext: PropTypes.shape({
    kinds: PropTypes.objectOf(
      PropTypes.shape({
        inputs: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }).isRequired
        ).isRequired,
        memberships: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            priceRange: PropTypes.string.isRequired,
            currency: PropTypes.string.isRequired,
            values: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired
    ).isRequired,
    benefits: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired).isRequired,
    checkbox: PropTypes.string.isRequired,
    descriptions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      [MEMBERSHIP]: PropTypes.string.isRequired,
      [PUBLISH]: PropTypes.bool.isRequired,
      [PAYMENT]: PropTypes.shape({
        [AMOUNT]: PropTypes.number.isRequired,
        [CURRENCY]: PropTypes.string.isRequired,
        [PAYMENT_METHOD]: PropTypes.string.isRequired,
      }).isRequired,
      [KIND]: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Step1;
export { default as Head } from '@/components/Head';
