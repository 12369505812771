import styled from 'styled-components';

import { fonts, palette } from '@/theme';

export const Container = styled.div`
  display: flex;
  position: relative;
  top: 0;
  left: -62px;
  width: calc(100% + 2 * 62px);
  border-top: 2px solid;
  box-shadow: 0 2px 0 0 ${palette.black};
  border-color: ${palette.black};
  padding: 0 62px;
  @media (max-width: 991px) {
    border-width: 1px;
    left: -16px;
    width: calc(100% + 2 * 16px);
    border-width: 1px;
    box-shadow: 0 1px 0 0 ${palette.black};
    padding: 0 16px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 50%;
  &:first-child {
    padding: 0 20px 0 10px;
  }
  &:first-child > svg {
    transform: rotate(180deg);
  }
  &:nth-child(2) {
    padding: 0 10px 0 20px;
    background-color: ${palette.black};
    border-left: 2px solid ${palette.black};
  }
  &:nth-child(2) * {
    color: ${palette.white};
  }
  @media (hover) {
    ${({ disabled }) =>
      !disabled &&
      `
      &:hover {
        box-shadow: inset 0 0 0 3px ${palette.black};
        background-color: ${palette.white};
      }
      &:hover * {
        color: ${palette.black};
      }
    `}
  }
  @media (max-width: 991px) {
    height: 30px;
    &:first-child,
    &:nth-child(2) {
      padding: 0 8px;
    }
    &:nth-child(2) {
      border-width: 1px;
    }
    &:first-child > svg {
      transform: rotate(180deg) scale(0.5) translate(12px, 0);
    }
    &:nth-child(2) > svg {
      transform: scale(0.5) translate(12px, 0);
    }
  }
  @media (max-width: 991px) and (hover) {
    ${({ disabled }) =>
      !disabled &&
      `
      &:hover {
        box-shadow: inset 0 0 0 2px ${palette.black};
      }
    `}
  }
`;

export const Text = styled.p`
  ${fonts.text}
  color: inherit;
  @media (max-width: 991px) {
    ${fonts.smallText22}
  }
`;

export const Step = styled.p`
  position: absolute;
  ${fonts.smallText13}
  top: 62px;
  right: 62px;
  padding: 3px;
  background-color: ${palette.white};
  @media (max-width: 991px) {
    display: none;
  }
`;
