import React, { useRef, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { palette } from '@/theme';

import { Container, InputWrap, Input, Label } from './styles';

const Checkbox = ({ type, selected, onChange, label, disabled }) => {
  const wrapRef = useRef();
  const labelRef = useRef();
  const id = useMemo(() => `checkbox-${type}`, [type]);
  const checkHeight = useCallback(() => {
    if (!window) return;

    const defaultHeight = window.innerWidth <= 991 ? 30 : 60;
    const labelHeight = labelRef.current?.getBoundingClientRect()?.height;

    if (labelHeight > defaultHeight) {
      wrapRef.current.style.borderColor = 'transparent';
    } else {
      wrapRef.current.style.borderColor = palette.black;
    }
  }, []);

  useEffect(() => {
    checkHeight();
    window.addEventListener('resize', checkHeight);

    return () => {
      window.removeEventListener('resize', checkHeight);
    };
  }, [checkHeight]);

  return (
    <Container disabled={disabled}>
      <InputWrap ref={wrapRef}>
        <Input id={id} type="checkbox" onChange={onChange} checked={selected} disabled={disabled} />
      </InputWrap>
      <Label ref={labelRef} htmlFor={id} disabled={disabled}>
        {label}
      </Label>
    </Container>
  );
};

Checkbox.defaultProps = { selected: false, disabled: false, onChange: () => null };
Checkbox.propTypes = {
  type: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default Checkbox;
