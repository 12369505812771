import styled from 'styled-components';

import { fonts, palette } from '@/theme';

export const Button = styled.button`
  width: calc(100% / 3);
  background-color: ${palette.white};
  border-color: ${palette.black};
  border-right: 2px solid;
  box-sizing: content-box;
  z-index: 1;
  &:nth-child(3) {
    border-right: none;
  }
  &:disabled {
    box-shadow: inset 0 0 0 3px ${palette.black};
  }
  @media (hover) {
    &:hover {
      box-shadow: inset 0 0 0 3px ${palette.black};
    }
  }
  @media (max-width: 991px) {
    display: flex;
    border-right: none;
    border-bottom: 1px solid;
    width: 100%;
    &:last-child {
      border-bottom: none;
    }
    &:disabled {
      box-shadow: inset 0 0 0 2px ${palette.black};
    }
  }
  @media (max-width: 991px) and (hover) {
    &:hover {
      box-shadow: inset 0 0 0 2px ${palette.black};
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  @media (max-width: 991px) {
    width: 50%;
    height: 90px;
    padding-top: 0;
    border-right: 1px solid ${palette.black};
  }
`;

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const Title = styled.p`
  ${fonts.large}
  text-align: center;
  @media (max-width: 1100px) {
    font-size: 120px;
  }
  @media (max-width: 991px) {
    ${fonts.text}
  }
`;

export const Text = styled.p`
  ${fonts.smallText22}
  text-align: center;
  @media (max-width: 991px) {
    ${fonts.smallText13}
  }
`;

export const ItemsWrap = styled.div`
  width: 100%;
  @media (max-width: 991px) {
    width: 50%;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 15px;
  border-top: 2px solid;
  border-color: ${palette.black};
  @media (max-width: 991px) {
    border-width: 1px;
    height: 30px;
    padding: 0 5px;
    &:first-of-type {
      border-top: none;
    }
  }
`;

export const ItemText = styled(Text)`
  text-align: left;
  margin-left: 20px;
  @media (max-width: 991px) {
    margin-left: 5px;
  }
`;
